import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect
} from "react"
import Script from "next/script"
import { useRouter } from "next/router"

export const FACEBOOK_PIXEL = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL
export const PIXEL_ACTIVE = !!FACEBOOK_PIXEL

export const script = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${FACEBOOK_PIXEL}');
`

type EventName = "PageView" | "Purchase" | "ViewContent" | "InitiateCheckout"

const FacebookContext = createContext<{ fbq: Function }>({ fbq: () => {} })

let interval: NodeJS.Timer | undefined
let queue: (any[] | undefined)[] = []

const drainQueueIfPossible = () => {
  console.log("starting queue drainage", queue)

  queue = queue
    .map(entry => {
      try {
        // @ts-expect-error
        window.fbq("track", ...entry)
        return undefined
      } catch (e) {
        console.error("queue drain error", e)
        return entry
      }
    })
    .filter(Boolean)

  console.log("drained queue", { queue })
}

export const useFacebook = () => useContext(FacebookContext).fbq

export const FacebookProvider = ({ children }: PropsWithChildren<{}>) => {
  const router = useRouter()

  const fbq = useCallback(
    (
      eventName: EventName,
      properties?: Record<string, any>,
      event?: { eventID: string }
    ) => {
      // Log, when failing -> queue
      try {
        // @ts-expect-error
        window.fbq("track", eventName, properties, event)
      } catch (e) {
        console.error("logging error", e)
        console.log("sending to queue again")
        queue.push([eventName, properties, event])
      }
    },
    []
  )

  const handleRouteChange = () => fbq("PageView")

  useEffect(() => {
    interval = setInterval(() => {
      drainQueueIfPossible()
    }, 1000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  useEffect(() => {
    handleRouteChange()

    router.events.on("routeChangeStart", handleRouteChange)

    return () => router.events.off("routeChangeStart", handleRouteChange)
  }, [router])

  return (
    <FacebookContext.Provider value={{ fbq }}>
      {children}

      {PIXEL_ACTIVE && (
        <Script
          id="facebook-pixel"
          dangerouslySetInnerHTML={{ __html: script }}
        />
      )}
    </FacebookContext.Provider>
  )
}
