import Head from "next/head"
import Script from "next/script"
import GlobalStyles from "@/components/GlobalStyles"
import { ThemeProvider } from "styled-components"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import { defaultTheme } from "@/utils/theme"
import { FacebookProvider } from "@/utils/facebook"
import type { AppProps } from "next/app"

const trackingId = process.env.GOOGLE_TRACKING_ID

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <link
          data-react-helmet="true"
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          data-react-helmet="true"
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          data-react-helmet="true"
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          data-react-helmet="true"
          rel="manifest"
          href="/site.webmanifest"
        />
        <link
          data-react-helmet="true"
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#1d1929"
        />
        <meta
          data-react-helmet="true"
          name="msapplication-TileColor"
          content="#ffffff"
        />
        <meta data-react-helmet="true" name="theme-color" content="#1d1929" />
      </Head>
      <PayPalScriptProvider
        options={{
          "client-id": process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID || ""
        }}
      >
        <ThemeProvider theme={defaultTheme}>
          <FacebookProvider>
            <GlobalStyles />
            <Component {...pageProps} />
          </FacebookProvider>
        </ThemeProvider>
      </PayPalScriptProvider>

      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
        strategy="afterInteractive"
      />

      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${trackingId}');
        `}
      </Script>
    </>
  )
}
export default MyApp
