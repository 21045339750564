import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

const GlobalStyles = createGlobalStyle`
  ${reset}
  html,
  body {
    font-family: ${({ theme }) => theme.fonts.text};
    background: ${({ theme }) => theme.background};
    
    -webkit-text-size-adjust: 100%;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
  }

#__next {
min-height: 100vh;
  display: flex;
  flex-direction: column;
}

  *, *:before, *:after {
    box-sizing: border-box;
  }
  
  a {
    display: inline-block;
    text-decoration: none;
    color: currentColor;
  }
  
  span {
    display: inline-block;
  }

  @font-face {
    font-family: "Inter";
    src: url("/fonts/Inter-Regular.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("/fonts/Inter-Medium.woff2") format("woff2");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: "Guillon";
    src: url("/fonts/Guillon-Regular.woff") format("woff"), url("/fonts/Guillon-Regular.woff2") format("woff2");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Guillon";
    src: url("/fonts/Guillon-Demi.woff") format("woff"), url("/fonts/Guillon-Demi.woff2") format("woff2");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: "Guillon";
    src: url("/fonts/Guillon-Bold.woff") format("woff"), url("/fonts/Guillon-Bold.woff2") format("woff2");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
`

export default GlobalStyles
