const fonts = {
  title: '"Guillon", sans-serif',
  text: '"Inter", sans-serif'
}

export const defaultTheme = {
  foreground: "#1D1929",
  higherContrast: "",
  highContrast: "#898890",
  moderateContrast: "#a8a6b1",
  mediumContrast: "",
  lowContrast: "#E5E5E5",
  lowerContrast: "#E6E8EA",
  background: "#FFFFFF",

  accentContrast: "",
  accent: "",

  fonts
}

export type TTheme = typeof defaultTheme
